  import {
      makeResponsive,
      placeTitle,
      placeImage,
      placeInput,
      placeSlider,
      hoverMe,
      placeRec,
      hiddenPt,
      fixedPt,
      clearInputFields,
      dragMe,
      placeArrow,
      placeGravity,
      placeText,
      placeLine,
      placePoint,
      placeGlider,
      placeRuler,
      placeLeftText,
      placeCircle,
      placeAngle,
      placeDash,
      placeLabel
  } from '../../../common/edliy_utils-circle';
  const Boxes = {
  box1: function () {
            var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-12, 13, 8, -7],keepaspectratio: true, axis:false, ticks:false, grid:true, pan:{enabled:false}, zoom:{enabled:false},  showCopyright:false, showNavigation:false});
            brd1.options.layer['line'] =2;
            brd1.options.layer['polygon'] =2;
            brd1.options.layer['image'] =8;
            brd1.options.board.minimizeReflow = 'none';
            brd1.options.text.highlight =false;
            brd1.options.text.fixed =true;
            brd1.options.text.highlight =false;
            brd1.options.text.display ='internal';
            brd1.options.text.cssStyle='fontFamily:Oswald',
            brd1.options.image.highlight =false;
            brd1.options.polygon.highlight =false;
            brd1.options.line.highlight =false;
            brd1.options.slider.highlight =false;
            brd1.options.arc.highlight =false;
            brd1.options.point.showInfoBox =false;
            brd1.options.point.highlight =false;
            makeResponsive(brd1);
            placeTitle(brd1, 'Convex Mirrors', '1. Place the Object 2. Observe the Image');
            brd1.suspendUpdate();
            var radius = 4;
            var xaxis = brd1.create('segment',[[-1000,0], [-0.5, 0]],{visible:false});
            var xaxis2 = brd1.create('line',[[-1000,0], [-0.5, 0]],{visible:true, dash:1, strokeWidth:1, strokeColor:'black', fixed: true});
            var yaxis = brd1.create('line',[[0,0], [0, 0.5]],{visible:false});
            //var focus1 = brd1.create('point', [-radius/2, 0], {strokeColor:'black', fillColor:'white', face:'o' , name:" f ", size:1, withLabel:true, fixed:true, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
            //var centerC = brd1.create('point', [-radius, 0], {strokeColor:'black', fillColor:'white', face:'o' , name:" c ", size:1, withLabel:true, fixed:true, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
            var focus1 = placePoint(brd1, radius/2, 0, 1, 'black', 'black');
            var centerC =placePoint(brd1, radius, 0, 1, 'black', 'black');
            var pts =placePoint(brd1, -1.95, 0.0, 1, 'red', 'red');
            centerC.setAttribute({fixed:true});
            focus1.setAttribute({fixed:true});
            pts.setAttribute({fixed:true});
            placeLabel(brd1, pts, 'P', 7, 7);
            placeLabel(brd1, focus1, 'F', 0, 10);
            placeLabel(brd1, centerC, 'C', 0, 10);
            //MIRROR
            var botPt = brd1.create('point', [0.75, -5],{face:'o' , name:'pp', size:2,withLabel:false, fixed:false, visible:false});
            var topPt = brd1.create('point', [0.75, 5],{face:'o' , name:'pp', size:2,withLabel:false, fixed:false, visible:false});
            //brd1.create('arc', [centerC , botPt, topPt],{strokeColor:'grey', strokeWidth:16});
            var mirror =brd1.create('arc', [centerC , topPt, botPt],{strokeColor:'black', strokeWidth:7});
            //object
            //object
            var player =placeImage(brd1, '/assets/candle.svg', -7, 0, 2, 0);
            player.setAttribute({fixed:false});
            hoverMe(brd1, player, 'Object', 5, -15);
            brd1.on('move', function(){player.moveTo([player.X(), 0.])});
            //
            //REAL IMAGE
            var track2 = brd1.create('segment',[[2,0], [-10, 0]],{visible:false});
            var trackb =brd1.create('glider', [()=>player.X()+1, 0, track2],{name:'B', size:0, face:'square',strokeColor:'black', visible: true});
            placeLabel(brd1, trackb, 'B(Drag me!)', 0, -15);
            var trackP = brd1.create('point', [()=>trackb.X(), function(){return trackb.Y() + 2}], {size:0, name:'A', face:'diamond', strokeColor:'black'});
            placeLabel(brd1, trackP, 'A', 0, 15);
            var obj =brd1.create('perpendicularsegment', [trackP, xaxis], {strokeWidth:4, name:' ', strokeColor:'green', visible: false});

            //1st line
            var ray1 =brd1.create('line', [trackP, [0, function(){return trackP.Y()}]], {strokeWidth:1, dash:1, visible:false});
            var int1 = brd1.create('intersection', [mirror, ray1], {visible: false});
            var ray11 =brd1.create('segment', [trackP, int1], {strokeWidth:1, dash:1, visible:true});
            var ray12 =brd1.create('line', [int1, focus1], {strokeWidth:1, dash:1, visible:true});

            //2nd line
            var ray2 =brd1.create('line', [trackP, centerC], {strokeWidth:1, dash:1, strokeColor: 'red', visible:true});
            var int2 = brd1.create('intersection', [ray2, yaxis], {visible: false});

            //OTHER IMAGE
            var int3 = brd1.create('intersection', [ray12, ray2], {size:0, name:'A\''});
            var perp =brd1.create('perpendicularpoint', [int3, xaxis], {strokeColor:'black', size:0, face:'square', name:'B\'', visible:true});
            var img =brd1.create('perpendicularsegment', [int3, xaxis], {strokeColor:'green', strokeWidth:4, visible: false});

            //Image
            //var player = brd1.create('image', ['/assets/player.svg', [function(){return trackP.X()-1}, 0], [2 , 2]], {visible: true, fixed: true});
            //brd1.create('segment', [trackb, trackP], {strokeWidth:8, visible:true});
            brd1.create('segment', [int3, perp], {strokeWidth:8, visible:true});
            /*var player2 = brd1.create('image', ['/assets/player.svg', [function(){if(int3.Y() < 0){return perp.X()-int3.Y()*0.5;} else{ return perp.X()-int3.Y()*0.5;}} , function(){return perp.Y()} ],
                                                                        [function(){return Math.abs(int3.Y()) } , function(){return Math.abs(int3.Y())}]],
                                                                        {visible: true, fixed: true, rotate: function(){if(int3.Y() < 0){return 180;} else{ return 0;} }
            });*/


            //Title and subtitle
            var ver1 = brd1.create('intersection', [mirror, xaxis], {visible: false});

            //var title1 = brd1.create('text', [0, 9, function(){if (trackb.X() > ver1.X()) {return '<b>Convex Mirror </b>' } else {return '<b>Concave Mirror </b>'}}],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
            //var title2 = brd1.create('text', [0, 7.5, '1. Place the Object  2. Observe the Image'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);

            var txt1 = brd1.create('text', [-10, 9.5, 'Object Size (AB): 1 mm'],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
            var txt2 = brd1.create('text', [-10, 8.5, function(){return 'Object Location (PB): ' + (trackb.X()+2).toFixed(2) + ' mm'}],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
            var txt3 = brd1.create('text', [-1, 9.5, function(){return 'Image Size (A\'B\'): '  + int3.Y().toFixed(2) + ' mm'}],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
            var txt4 = brd1.create('text', [-1, 8.5, function(){return 'Image Location (PB\'): ' + (int3.X()+2).toFixed(2) + ' mm'}],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
            var txt5 = brd1.create('text', [-1, 7.5, function(){return 'Nature of Image: ' }],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);

            var txt6 = brd1.create('text', [2.5, 7.5, function(){    if(trackb.X() < ver1.X() && int3.X() < ver1.X() ){
                                                                        return 'Real & Inverted';
                                                                    }

                                                                    else if(trackP.X() < ver1.X() && int3.X() > ver1.X() ){
                                                                        return 'Virtual & Upright';
                                                                    }

                                                                    else if(trackP.X() > ver1.X() && int3.X() > ver1.X() ){
                                                                        return 'Real & Inverted';
                                                                    }

                                                                    else if(trackP.X() > ver1.X() && int3.X() < ver1.X() ){
                                                                        return 'Virtual & Upright';
                                                                    }

            }],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
            /*var txt7 = brd1.create('text', [2, 7.5, function(){    if(int3.Y() < 0  ){
                                                                        return 'Inverted';
                                                                    }

                                                                    else if(int3.Y() > 0 ){
                                                                        return 'Upright';
                                                                    }}],
                                                                    {fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},
            );*/

            brd1.unsuspendUpdate();
    },
}
export default Boxes;
